import styled from "styled-components";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right_gray01.svg";

const Container = styled.main`
  min-height: 100vh;
  padding-bottom: 100px;

  & section {
    padding-top: 40px;
  }

  & section:not(:last-child) {
    border-bottom: 10px solid var(--gray03);
  }

  & .contentsTitle {
    font-size: 22px;
    font-weight: bold;
  }

  & .mySection {
    & .userInfo {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 20px;

      & .profileImg {
        flex-shrink: 0;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;
      }

      & .nickname {
        font-size: 26px;
        font-weight: 600;
      }
    }

    & .couponWrap {
      margin-bottom: 20px;

      & .insideButton {
        flex-shrink: 0;
        width: 120px;
      }
    }

    & .pointWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      margin-bottom: 40px;
      padding: 0 20px;
      border: 1px solid var(--gray02);
      border-radius: 10px;

      & p {
        font-weight: 600;
        color: var(--gray01);
      }

      & .point {
        font-size: 22px;
        font-weight: 600;
      }
    }

    & .myMenu {
      display: flex;
      gap: 20px;
      margin-bottom: 40px;

      & .myMenuList {
        flex: 1;
        text-align: center;

        & .icon {
          width: 34px;
          height: 34px;
          margin: 0 auto 20px;
        }
      }
    }

    & .interest {
      display: flex;
      align-items: center;
      height: 90px;
      margin-bottom: 20px;
      padding: 0 15px;
      background-color: var(--purple);
      border-radius: 10px;

      & .checkIcon {
        width: 34px;
        height: 34px;
        margin-right: 10px;
      }

      & .textWrap {
        & p:first-child {
          margin-bottom: 6px;
          font-weight: bold;
        }

        & p:last-child {
          font-size: 18px;
          color: var(--gray01);
        }
      }

      & .arrowIcon {
        width: 20px;
        height: 20px;
        margin-left: auto;
      }
    }
  }

  & .programSection {
    padding-bottom: 40px;

    & .titleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      & a {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 18px;
        color: var(--main);

        & .icon {
          width: 12px;
          height: 12px;
          filter: var(--main-filter);
        }
      }
    }

    & .programList {
      border: 1px solid var(--gray02);

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      & a {
        padding: 20px 15px;
      }

      & .top,
      & .location,
      & .detailTop,
      & .detailBottom {
        display: flex;
        align-items: center;
      }

      & .top {
        gap: 10px;
        margin-bottom: 30px;

        & .location {
          font-weight: 600;
          color: var(--main);

          & .icon {
            width: 30px;
            height: 30px;
          }
        }
      }

      & .date {
        margin-bottom: 10px;
        font-size: 22px;
        color: var(--gray01);

        & .people {
          margin-left: 15px;
        }
      }

      & .detailList {
        font-size: 22px;

        &:not(:last-child) {
          margin-bottom: 30px;
        }

        & .detailTop {
          justify-content: space-between;
          margin-bottom: 20px;
          padding-bottom: 20px;
          font-weight: 600;
          border-bottom: 1px solid var(--gray02);

          & .detailTitle {
            max-width: 280px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          & .time {
            color: var(--gray01);
          }
        }

        & .detailBottom {
          color: var(--gray01);

          & .icon {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }

          & .room {
            margin-left: 20px;
          }
        }
      }
    }

    & .moreButton {
      margin-top: 20px;
    }

    & .emptyText {
      padding: 60px 0 40px;
      font-weight: 400;
      color: var(--gray01);
      text-align: center;
    }
  }

  & .utilSection {
    & .codeContainer {
      margin-bottom: 40px;

      & .contentsTitle {
        margin-bottom: 20px;
      }

      & .codeWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 96px;
        background-color: var(--gray03);
        border-radius: 5px;

        & button {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          gap: 10px;

          & .icon {
            display: inline-block;
            width: 34px;
            height: 34px;
          }

          & .code {
            font-size: 30px;
            font-weight: 600;
            text-decoration: underline;
            text-underline-offset: 4px;
          }
        }
      }
    }

    & .utilList {
      &:not(:last-child) {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--gray02);
      }

      & a,
      button {
        font-size: 20px;
        font-weight: 600;
        color: var(--gray01);
      }

      & a {
        background: url(${IconArrowRight}) no-repeat right center / 16px;

        & span {
          margin-left: 10px;
          color: var(--main);
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }
    }
  }

  @media (max-width: 520px) {
    padding-bottom: calc(100vw * (100 / 500));

    & section {
      padding-top: calc(100vw * (40 / 500));
    }

    & section:not(:last-child) {
      border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);
    }

    & .contentsTitle {
      font-size: calc(100vw * (22 / 500));
    }

    & .mySection {
      & .userInfo {
        gap: calc(100vw * (15 / 500));
        margin-bottom: calc(100vw * (20 / 500));

        & .profileImg {
          width: calc(100vw * (90 / 500));
          height: calc(100vw * (90 / 500));
        }

        & .nickname {
          font-size: calc(100vw * (26 / 500));
        }
      }

      & .couponWrap {
        margin-bottom: calc(100vw * (20 / 500));

        & .insideButton {
          width: calc(100vw * (120 / 500));
        }
      }

      & .pointWrap {
        height: calc(100vw * (70 / 500));
        margin-bottom: calc(100vw * (40 / 500));
        padding: 0 calc(100vw * (20 / 500));
        border-radius: calc(100vw * (10 / 500));

        & .point {
          font-size: calc(100vw * (22 / 500));
        }
      }

      & .myMenu {
        gap: calc(100vw * (20 / 500));
        margin-bottom: calc(100vw * (40 / 500));

        & .myMenuList {
          & .icon {
            width: calc(100vw * (34 / 500));
            height: calc(100vw * (34 / 500));
            margin: 0 auto calc(100vw * (20 / 500));
          }
        }
      }

      & .interest {
        height: calc(100vw * (90 / 500));
        margin-bottom: calc(100vw * (20 / 500));
        padding: 0 calc(100vw * (15 / 500));
        border-radius: calc(100vw * (10 / 500));

        & .checkIcon {
          width: calc(100vw * (34 / 500));
          height: calc(100vw * (34 / 500));
          margin-right: calc(100vw * (10 / 500));
        }

        & .textWrap {
          & p:first-child {
            margin-bottom: calc(100vw * (6 / 500));
          }

          & p:last-child {
            font-size: calc(100vw * (18 / 500));
          }
        }

        & .arrowIcon {
          width: calc(100vw * (20 / 500));
          height: calc(100vw * (20 / 500));
        }
      }
    }

    & .programSection {
      padding-bottom: calc(100vw * (40 / 500));

      & .titleContainer {
        margin-bottom: calc(100vw * (20 / 500));

        & a {
          gap: calc(100vw * (6 / 500));
          font-size: calc(100vw * (18 / 500));

          & .icon {
            width: calc(100vw * (12 / 500));
            height: calc(100vw * (12 / 500));
          }
        }
      }

      & .programList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (30 / 500));
        }

        & a {
          padding: calc(100vw * (20 / 500)) calc(100vw * (15 / 500));
        }

        & .top {
          gap: calc(100vw * (10 / 500));
          margin-bottom: calc(100vw * (30 / 500));

          & .location {
            & .icon {
              width: calc(100vw * (30 / 500));
              height: calc(100vw * (30 / 500));
            }
          }
        }

        & .date {
          margin-bottom: calc(100vw * (10 / 500));
          font-size: calc(100vw * (22 / 500));

          & .people {
            margin-left: calc(100vw * (15 / 500));
          }
        }

        & .detailList {
          font-size: calc(100vw * (22 / 500));

          &:not(:last-child) {
            margin-bottom: calc(100vw * (30 / 500));
          }

          & .detailTop {
            margin-bottom: calc(100vw * (20 / 500));
            padding-bottom: calc(100vw * (20 / 500));

            & .detailTitle {
              max-width: calc(100vw * (280 / 500));
            }
          }

          & .detailBottom {
            & .icon {
              width: calc(100vw * (24 / 500));
              height: calc(100vw * (24 / 500));
              margin-right: calc(100vw * (10 / 500));
            }

            & .room {
              margin-left: calc(100vw * (20 / 500));
            }
          }
        }
      }

      & .moreButton {
        margin-top: calc(100vw * (20 / 500));
      }

      & .emptyText {
        padding: calc(100vw * (60 / 500)) 0 calc(100vw * (40 / 500));
      }
    }

    & .utilSection {
      & .codeContainer {
        margin-bottom: calc(100vw * (40 / 500));

        & .contentsTitle {
          margin-bottom: calc(100vw * (20 / 500));
        }

        & .codeWrap {
          height: calc(100vw * (96 / 500));
          border-radius: calc(100vw * (5 / 500));

          & button {
            gap: calc(100vw * (10 / 500));

            & .icon {
              width: calc(100vw * (34 / 500));
              height: calc(100vw * (34 / 500));
            }

            & .code {
              font-size: calc(100vw * (30 / 500));
              text-underline-offset: calc(100vw * (4 / 500));
            }
          }
        }
      }

      & .utilList {
        &:not(:last-child) {
          padding-bottom: calc(100vw * (20 / 500));
          margin-bottom: calc(100vw * (20 / 500));
        }

        & a,
        button {
          font-size: calc(100vw * (20 / 500));
        }

        & a {
          background: url(${IconArrowRight}) no-repeat right center /
            calc(100vw * (16 / 500));

          & span {
            margin-left: calc(100vw * (10 / 500));
            text-underline-offset: calc(100vw * (3 / 500));
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
