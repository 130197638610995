import connector from "service/connector";

const recommend = async (data) => {
  return await connector.connectFetchController(
    "check/recommend?recommend_code=" + data,
    "GET"
  );
};
const promotion = async (data) => {
  return await connector.authConnector(
    "promotion?code=" + data + "&type=1",
    "GET"
  );
};

const doPromotion = async (data) => {
  return await connector.authConnector(
    "promotion/use?code=" + data + "&type=1",
    "GET"
  );
};

export { recommend, promotion, doPromotion };

export default {
  recommend,
  promotion,
  doPromotion,
};
