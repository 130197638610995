import Styles from "./styles";
import utils, { etc } from "utils";
import { common, layout, ui } from "components";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  useAuth,
  useIamport,
  useModals,
  usePoint,
  useSupport,
  useTransaction,
} from "hooks";

// img
import IconWarning from "resources/image/icon/icon_warning.svg";
import IconCoupon from "resources/image/icon/icon_coupon.svg";
import IconCreditCard from "resources/image/icon/icon_credit_card.svg";
import IconHand from "resources/image/icon/icon_hand.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_mini.svg";

function PaymentPage() {
  const authInfo = useAuth({});
  const modalOption = useModals();
  const location = useLocation();
  const transactionInfo = useTransaction({
    transactionList: location.state?.productList,
  });
  const supportInfo = useSupport({
    options: { policy: true },
  });

  const iamport = useIamport({});
  const pointInfo = usePoint({ isTotalPoint: true });
  const [memberList, setMemberList] = useState([]);
  const [yourselfCheck, setYourselfCheck] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    tel: "",
    point: "",
  });
  const [totalPoint, setTotalPoint] = useState(0);
  const [payCheck, setPayCheck] = useState(false);
  const [allCheck, setAllCheck] = useState(false);
  const [check, setCheck] = useState([]);
  const isLoad = useRef(false);

  useEffect(() => {
    let resultList = [];
    for (let i = 0; i < location?.state?.transactionList[0]?.amount; i++) {
      resultList.push({});
    }
    setMemberList(resultList);
  }, []);

  useEffect(() => {
    let isIn = false;
    for (let i = 0; i < memberList.length; i++) {
      if (memberList[i].phone == authInfo.userData?.phone) {
        isIn = true;
      }
    }
    if (authInfo.userData?.phone == null) {
    } else setYourselfCheck(isIn);
  }, [memberList, authInfo.userData]);

  const handleAllCheck = (checked) => {
    if (checked) {
      const idArray = [];
      essentialCheckList.forEach((el) => idArray.push(el.id));
      setCheck(idArray);
      setAllCheck(true);
    } else {
      setCheck([]);
      setAllCheck(false);
    }
  };

  useEffect(() => {
    setAllCheck(check.length == 3);
  }, [check]);

  // 단일 체크 함수
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheck(check.concat(id));
    } else {
      setCheck(check.filter((el) => el !== id));
    }
  };

  const validate = () => {
    if (memberList.filter((value) => !value.name || !value.phone)[0]) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "수강 회원 정보를 입력해 주세요.";
        return { ...e };
      });
      return false;
    }

    if (payCheck == false) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "결제 방법을 선택해 주세요.";
        return { ...e };
      });
      return false;
    }

    if (check.length != 3) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "필수 약관에 동의해 주세요.";
        return { ...e };
      });
      return false;
    }

    return true;
  };

  const doPay = async () => {
    if (validate() == false) {
      return;
    }

    if (isLoad.current) return;
    isLoad.current = true;
    let result = await iamport.onClickPayment({
      productTitle: transactionInfo.transactionList[0]?.detail?.title,
      type: transactionInfo.transactionList[0]?.detail?.type || 3,
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      method: payCheck == "creditCard" ? "CARD" : "trans",
      total_point: totalPoint,
      total_price: transactionInfo.getTotalPrice() - totalPoint,
      transaction_group_list: transactionInfo.getGenerateTransactionList(),
      transaction_member_list: memberList,
    });

    if (result.description == "수량 부족") {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "예약 가능한 인원수가 없습니다.";
        return { ...e };
      });
      isLoad.current = false;
    }

    if (result == false) {
      isLoad.current = false;
    }
  };

  // data
  const inputList = [
    {
      value: inputs.name,
      name: "name",
      placeholder: "이름",
    },
    {
      value: inputs.tel,
      type: "number",
      name: "tel",
      placeholder: "숫자만 입력",
    },
    {
      value: inputs.point,
      type: "text",
      name: "point",
      placeholder: "0",
    },
  ];
  const payButtonList = [
    {
      id: "creditCard",
      icon: IconCreditCard,
      text: "신용카드",
    },
    {
      id: "hypotheticalAccount",
      icon: IconHand,
      text: "가상계좌",
    },
  ];
  const essentialCheckList = [
    {
      id: "essential01",
      labelText: "전자금융거래 이용약관 동의",
      modal: () => {
        supportInfo.getModal(1);
      },
    },
    {
      id: "essential02",
      labelText: "개인정보 수집 및 이용 동의",
      modal: () => {
        supportInfo.getModal(2);
      },
    },
    {
      id: "essential03",
      labelText: "개인정보 제3자 제공 동의",
      modal: () => {
        supportInfo.getModal(3);
      },
    },
  ];
  const termsLinkList = [
    {
      text: "수강취소/환불 안내",
      modal: () => {
        supportInfo.getModal(4);
      },
    },
    {
      text: "클래스 환불 규정",
      modal: () => {
        supportInfo.getModal(5);
      },
    },
    {
      text: "평생 교육법 환불 규정",
      modal: () => {
        supportInfo.getModal(6);
      },
    },
  ];

  return (
    <div className="wrap">
      <common.SubHeader pageName={"결제하기"} />

      <Styles.Container>
        <section className="programInfoSection">
          <layout.ProgramInfoSection
            productInfo={transactionInfo.transactionList[0]}
            transactionList={location?.state?.transactionList}
          />
        </section>

        <section className="detailSection">
          <layout.ProgramDetailSection
            productInfo={transactionInfo.transactionList[0]}
            transactionList={location?.state?.transactionList}
          />
        </section>

        {location?.state?.transactionList[0]?.option_relate_list?.length >
          0 && (
          <section className="optionSection">
            <layout.ProgramOptionSection
              productInfo={transactionInfo.transactionList[0]}
              transactionList={location?.state?.transactionList}
            />
          </section>
        )}

        <section className="userInfoSection">
          <div className="inner">
            <div className="titleContainer">
              <div className="title">
                <span>[필수]</span> 수강 회원 정보
              </div>

              <div className="yourselfCheck">
                <ui.input.BasicCheckbox
                  id={"yourselfCheck"}
                  labelText={"본인 포함"}
                  checked={yourselfCheck}
                  onChange={(e) => {
                    if (e.target.checked) {
                      memberList[0].name = authInfo.userData?.name;
                      memberList[0].phone = authInfo.userData?.phone;
                    }
                    setYourselfCheck(e.target.checked);
                  }}
                />
              </div>
            </div>

            <ul className="inputLabel">
              <li className="inputLabelList name">이름</li>
              <li className="inputLabelList tel">전화번호</li>
            </ul>

            <ul className="input">
              {memberList.map((member, index) => {
                return (
                  <li className="inputList" key={"inputList" + index}>
                    <div className="name">
                      <ui.input.BasicCustomInput
                        value={member.name}
                        setValue={(e) => {
                          member.name = String(e.target.value).slice(0, 15);
                          setMemberList((e) => [...e]);
                        }}
                        placeholder={"이름"}
                      />
                    </div>

                    <div className="tel">
                      <ui.input.BasicCustomInput
                        value={
                          !member.phone
                            ? ""
                            : member.phone?.substr(0, 3) +
                              (member.phone?.length > 3
                                ? "-" +
                                  member.phone?.substr(3, 4) +
                                  (member.phone?.length > 7
                                    ? "-" + member.phone?.substr(7, 4)
                                    : "")
                                : "")
                        }
                        setValue={(e) => {
                          let tels = String(e.target.value)
                            .replace(/-/gi, "")
                            .replace(/[^0-9]/gi, "")
                            .slice(0, 11);
                          member.phone = tels;
                          setMemberList((e) => [...e]);
                        }}
                        placeholder={"숫자만 입력"}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="couponSection">
          <div className="inner">
            <div className="titleContainer">
              <div className="title">쿠폰</div>

              <div className="applicable">
                적용 가능 쿠폰
                <b>0장</b>
              </div>
            </div>

            <div className="couponButton">
              <ui.button.BasicButton
                buttonText={"쿠폰 적용하기"}
                buttonType={"line"}
                icon={IconCoupon}
                onClick={() => {
                  modalOption.setCouponModal((e) => {
                    e.show = true;

                    return { ...e };
                  });
                }}
              />
            </div>

            <p className="guideText">
              <span className="icon">
                <img src={IconWarning} alt="IconWarning" />
              </span>
              실 결제금액이 쿠폰 할인가 보다 낮은 경우, <br />
              차액은 별도 쿠폰이나 현금으로 환급되지 않습니다.
            </p>
          </div>
        </section>

        <section className="pointSection">
          <div className="inner">
            <div className="titleContainer">
              <div className="title">포인트</div>

              <div className="allPointCheck">
                <ui.input.BasicCheckbox
                  id={"allPointCheck"}
                  labelText={"전액 사용"}
                  checked={
                    inputs.point != 0 &&
                    inputs.point.replace(/[^0-9]/g, "") ==
                      Math.min(
                        pointInfo.maxPoint,
                        transactionInfo.getTotalPrice()
                      )
                  }
                  onChange={(e) => {
                    if (Math.min(pointInfo.maxPoint) < 100) {
                      modalOption.setToastModal((e) => {
                        e.show = true;
                        e.text = "포인트는 100원 이상 사용 가능합니다.";
                        return { ...e };
                      });
                      return;
                    }

                    setInputs((e) => {
                      return {
                        ...e,
                        point: etc.numberWithCommas(
                          String(
                            Math.min(
                              inputs.point == pointInfo.maxPoint
                                ? 0
                                : pointInfo.maxPoint,
                              transactionInfo.getTotalPrice()
                            )
                          )
                        ),
                      };
                    });
                  }}
                />
              </div>
            </div>

            <div className="inputWrap">
              <ui.input.BasicInput
                data={inputList[2]}
                setInputs={setInputs}
                value={inputs.point}
                setValue={(e) => {
                  let point = String(e.target.value).replace(/[^0-9]/g, "");
                  point =
                    point > pointInfo.maxPoint ? pointInfo.maxPoint : point;
                  point = Math.min(point, transactionInfo.getTotalPrice());
                  setInputs((inp) => {
                    return { ...inp, point: utils.etc.numberWithCommas(point) };
                  });
                }}
              >
                <div className="confirmButton">
                  <ui.button.BasicButton
                    size={"smallText"}
                    buttonText={"확인"}
                    buttonType={"black"}
                    onClick={() => {
                      let point = String(inputs.point).replace(/[^0-9]/g, "");
                      point = !point ? 0 : point;

                      if (point != 0 && point < 100) {
                        modalOption.setToastModal((e) => {
                          e.show = true;
                          e.text = "포인트는 100원 이상 사용 가능합니다.";
                          return { ...e };
                        });
                        return;
                      }

                      if (parseInt(point) <= parseInt(pointInfo.maxPoint)) {
                        setTotalPoint(point);
                      } else {
                        setTotalPoint(pointInfo.maxPoint);
                      }
                    }}
                  />
                </div>
              </ui.input.BasicInput>
            </div>

            <div className="available">
              사용 가능 포인트
              <b>{utils.etc.numberWithCommas(pointInfo?.totalPoint)}원</b>
            </div>
          </div>
        </section>

        <section className="payButtonSection">
          <div className="inner">
            <div className="title">결제하기</div>

            <ul className="payButton">
              {payButtonList?.map((item, index) => {
                return (
                  <li className="payButtonList" key={"payButtonList" + index}>
                    <input
                      type="radio"
                      name="pay"
                      id={item.id}
                      checked={payCheck === item.id ? true : false}
                      onChange={(e) => {
                        setPayCheck(e.target.id);
                      }}
                    />
                    <label htmlFor={item.id}>
                      <span className="icon">
                        <img src={item.icon} alt="buttonIcon" />
                      </span>

                      <span className="text">{item.text}</span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="priceSection">
          <layout.ProgramPriceSection
            data={{
              point: totalPoint,
              coupon: 0,
              price: transactionInfo.getTotalPrice(),
            }}
            totalPrice={utils.etc.numberWithCommas(
              transactionInfo.getTotalPrice() - totalPoint
            )}
          />
        </section>

        <section className="termsSection">
          <div className="inner">
            <div className="allCheck">
              <ui.input.BasicCheckbox
                id={"all"}
                labelText={"전체 동의"}
                checked={allCheck}
                onChange={(e) => {
                  handleAllCheck(e.target.checked);
                }}
              />
            </div>

            <div className="essentialCheck">
              <p className="title">필수 이용 약관</p>
              <ul>
                {essentialCheckList?.map((item, index) => {
                  return (
                    <li
                      className="essentialCheckList"
                      key={"essentialCheckList" + index}
                    >
                      <ui.input.BasicCheckbox
                        id={item.id}
                        labelText={item.labelText}
                        checked={check.includes(item.id) ? true : false}
                        onChange={(e) =>
                          handleSingleCheck(e.target.checked, e.target.id)
                        }
                      />

                      {/* //* 퍼블 디자인 깨짐 우려 주석. 원본 코드 */}
                      {/* <Link to={item.link} className="icon">
                        <img src={IconArrowRight} alt="IconArrowRight" />
                      </Link> */}

                      {item.modal && (
                        <button
                          className="icon"
                          type="button"
                          onClick={item.modal}
                        >
                          <img src={IconArrowRight} alt="IconArrowRight" />
                        </button>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>

            <ul className="termsLink">
              {termsLinkList.map((item, index) => {
                return (
                  <li className="termsLinkList" key={"termsLinkList" + index}>
                    {item.text}{" "}
                    <button className="icon" type="button" onClick={item.modal}>
                      <img src={IconArrowRight} alt="IconArrowRight" />
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <div className="application">
          <div className="inner">
            <div className="price">
              {utils.etc.numberWithCommas(
                transactionInfo.getTotalPrice() - totalPoint
              )}
              원
            </div>

            <div className="applicationButton">
              <ui.button.BasicButton buttonText={"결제하기"} onClick={doPay} />
            </div>
          </div>
        </div>
      </Styles.Container>
    </div>
  );
}

export default PaymentPage;
