import { api } from "service";

export default function useCodeCheck() {
  const checkRecommend = async (code) => {
    return await api.codeCheck.recommend(code);
  };
  const checkPromotion = async (code) => {
    return await api.codeCheck.promotion(code);
  };
  const doPromotion = async (code) => {
    return await api.codeCheck.doPromotion(code);
  };

  return {
    checkRecommend,
    checkPromotion,
    doPromotion,
  };
}
