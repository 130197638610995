import { useAuth } from "hooks";

const AuthRoute = ({ children }) => {
  let authInfo = useAuth({});

  if (authInfo.isLoad === false) {
    if (authInfo.isLogin === true) {
      return children;
    } else {
      if (window.location.pathname.includes("/payment-history")) {
        window.location.replace("/login");
      } else if (window.location.pathname.includes("/payment/complete")) {
        window.location.replace("/login");
      } else if (window.location.pathname.includes("/mypage")) {
        window.location.replace("/login");
      } else window.location.replace("/");
      return <></>;
    }
  }
};

export default AuthRoute;
