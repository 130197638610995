import Styles from "./styles";
import { common, ui } from "components";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { useModals, useSupport } from "hooks";
import { api } from "service";
import utils from "utils";

// img
import IconWarning from "resources/image/icon/icon_warning.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_mini.svg";

function MyPaymentCancelPage() {
  const navigate = useNavigate();
  const modalOption = useModals();

  const [check, setCheck] = useState(false);
  const isLoad = useRef(false);

  const supportInfo = useSupport({
    options: { policy: true },
  });

  // map
  const tableList = [
    {
      title: "프로그램 3일 전 취소 시",
      text: "전액 결제 취소",
    },
    {
      title: "프로그램 2일 전 취소 시",
      text: "20% 차감 후 취소",
    },
    {
      title: "프로그램 1일 전 취소 시",
      text: "50% 차감 후 취소",
    },
    {
      title: "프로그램 진행 당일",
      text: "결제 취소 불가",
    },
  ];
  const guideList = [
    "- 결제 취소 기준일은 프로그램 시작일입니다.",
    "- 노쇼는 취소가 불가능합니다.",
    "- 사용하신 포인트는 전액 환불됩니다.",
    "- 사용하신 쿠폰은 재발급되지 않습니다.",
  ];
  const termsLinkList = [
    {
      text: "수강취소/환불 안내",
      link: "/contact/terms",
      modal: () => {
        supportInfo.getModal(4);
      },
    },
    {
      text: "클래스 환불 규정",
      link: "/contact/terms",
      modal: () => {
        supportInfo.getModal(5);
      },
    },
    {
      text: "평생 교육법 환불 규정",
      link: "/contact/terms",
      modal: () => {
        supportInfo.getModal(6);
      },
    },
  ];

  return (
    <div className="wrap">
      <common.SubHeader pageName={"결제 취소"} />

      <Styles.Container>
        <section className="noticeSection">
          <div className="inner">
            <div className="icon">
              <img src={IconWarning} alt="IconWarning" />
            </div>

            <p className="title">
              결제를 <span>취소</span> 하시겠습니까?
            </p>

            <div className="table">
              <p className="tableTitle">결제 취소 규정</p>

              <ul>
                {tableList.map((item, index) => {
                  return (
                    <li className="tableList" key={"tableList" + index}>
                      <div className="th">{item.title}</div>
                      <div className="td">{item.text}</div>
                    </li>
                  );
                })}
              </ul>
            </div>

            <ul className="guide">
              {guideList.map((item, index) => {
                return (
                  <li className="guideList" key={"guideList" + index}>
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="termsSection">
          <div className="inner">
            <ul className="termsLink">
              {termsLinkList.map((item, index) => {
                return (
                  <li className="termsLinkList" key={"termsLinkList" + index}>
                    {item.text}{" "}
                    <button type="button" className="icon" onClick={item.modal}>
                      <img src={IconArrowRight} alt="IconArrowRight" />
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <div className="check">
          <ui.input.BasicCheckbox
            id={"yourselfCheck"}
            labelText={"취소 및 환불 규정을 확인했습니다."}
            checked={check}
            onChange={(e) => {
              setCheck(e.target.checked);
            }}
          />
        </div>

        <div className="buttonContainer">
          <div className="inner">
            <div className="buttonWrap">
              <ui.button.BasicButton
                buttonText={"취소"}
                buttonType={"green"}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <div className="buttonWrap">
              <ui.button.BasicButton
                buttonText={"결제 취소"}
                onClick={async () => {
                  if (check) {
                    if (isLoad.current) return;
                    isLoad.current = true;

                    let data = { id: utils.etc.getSearchParam().id };
                    let result = await api.transaction.cancelTransaction(
                      JSON.stringify(data)
                    );
                    modalOption.setDefaultModal((e) => {
                      e.show = true;
                      e.closeButton = false;
                      e.icon = false;
                      e.title = result.success
                        ? "결제가 취소되었습니다."
                        : "결제 취소에 실패하였습니다.";
                      e.text = false;
                      e.confirmButtonText = "확인";
                      e.confirmButtonClick = () => {
                        isLoad.current = false;
                        navigate("/mypage/program");
                        modalOption.clearModals();
                      };
                      return { ...e };
                    });
                  } else {
                    modalOption.setToastModal((e) => {
                      e.show = true;
                      e.text = "취소 및 환불 규정을 확인해 주세요.";

                      return { ...e };
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Styles.Container>
    </div>
  );
}

export default MyPaymentCancelPage;
