import useModals from "hooks/useModals";
import { api } from "service";
import { DOMAIN } from "service/connector";
import { useAtom } from "jotai";
import { authAtom } from "store/atoms";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

export default function useAuth({ detail }) {
  const navigate = useNavigate();
  const modalOption = useModals();
  const [signInfo, setSignInfo] = useAtom(authAtom.signInfo);
  const [userData, setUserData] = useAtom(authAtom.userInfo);
  const isLogin = !userData.nickname ? false : true;

  const userQuery = useQuery("userData", api.auth.getUser, {
    onSuccess: (e) => {
      if (e == null) {
      } else {
        if (window.location.pathname.includes("landing")) {
          navigate("/");
        }
        setUserData(e);
      }
    },
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
  });

  const findNickName = async (userNickName) => {
    return await api.auth.getCheckName(userNickName);
  };

  // 인증요청
  const sendIdentifyCode = async (value, type) => {
    let data = {
      identify_number: value,
      type: type == "phone" ? 1 : 0,
    };
    let result = await api.sms.sendSms(JSON.stringify(data));
    return result;
  };

  const certification = () => {
    if (isLogin === false) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.closeButton = true;
        e.icon = false;
        e.title = (
          <>
            오뉴의 <span className="color">멤버</span>가 되어보세요!
          </>
        );
        e.text = "로그인/회원가입 후 이용하실 수 있습니다.";
        e.confirmButtonText = "로그인/회원가입";
        e.confirmButtonClick = () => {
          navigate("/login");
          modalOption.clearModals();
        };

        return { ...e };
      });
      return false;
    }
    return true;
  };

  const socialLogin = async (type) => {
    return await api.auth.socialLogin(type);
  };
  const socialSignUp = async (data) => {
    sessionStorage.removeItem("signUp");
    const result = await api.auth.socialSignUp(JSON.stringify(data));
    result.success && navigate("/signup/complete", { state: true });
  };

  const userDetail = useQuery(
    ["user_detail", detail],
    () => {
      return api.auth.userDetail(detail);
    },
    {
      enabled: !!detail,
      refetchOnWindowFocus: false,
    }
  );

  const userUpdate = async (data) => {
    data.current_amount = data.current_amount === "" ? 0 : data.current_amount;
    data.prev_amount = data.prev_amount === "" ? 0 : data.prev_amount;

    const result = await api.auth.userUpdate(JSON.stringify(data));
    if (result.success) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = "수정이 완료되었습니다.";
        e.text = false;
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {
          navigate("../", { relative: "path" });
          modalOption.clearModals();
        };
        e.cancelButtonText = false;
        return { ...e };
      });
    }
  };

  const logout = () => {
    setUserData(authAtom.userInfo);
    document.cookie = `jwt=;path=/;domain=${DOMAIN};max-age:-1`;
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: "LOGOUT" }));
    }
  };

  const logoutModal = async () => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.closeButton = true;
      e.title = "로그아웃 하시겠습니까?";
      e.confirmButtonText = "로그아웃";
      e.confirmButtonClick = () => {
        logout();
        modalOption.setDefaultModal((e) => {
          e.show = true;
          e.closeButton = false;
          e.title = "로그아웃 되었습니다.";
          e.confirmButtonText = "확인";
          e.confirmButtonClick = () => {
            window.location.replace("/");
            modalOption.clearModals();
          };

          return { ...e };
        });
      };

      return { ...e };
    });
  };

  const resign = (data) => {
    const postData = new FormData();
    postData.append(
      "reason",
      data.reason == "기타" ? data.textarea : data.reason
    );

    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.icon = false;
      e.closeButton = true;
      e.title = "탈퇴하시겠습니까?";
      e.text = "탈퇴 안내를 확인하셨습니까?";
      e.confirmButtonText = "탈퇴하기";
      e.confirmButtonClick = async () => {
        const result = await api.auth.resign(postData);
        if (result?.success) {
          logout();
          modalOption.setDefaultModal((e) => {
            e.show = true;
            e.closeButton = false;
            e.title = "탈퇴가 완료되었습니다.";
            e.text = false;
            e.confirmButtonText = "메인으로";
            e.confirmButtonClick = () => {
              window.location.replace("/");
              modalOption.clearModals();
            };
            return { ...e };
          });
        }
      };
      return { ...e };
    });
  };

  return {
    userData,
    setUserData,

    signInfo,
    setSignInfo,

    isLogin,
    userQuery: userQuery,
    isLoad: userQuery.isLoading,

    findNickName,
    sendIdentifyCode,

    certification,

    socialLogin,
    socialSignUp,
    userDetail,
    userUpdate,
    logoutModal,
    resign,
  };
}
