import Styles from "./styles";
import { api } from "service";
import { useNavigate } from "react-router-dom";
import { common, layout } from "components";
import { useBanner, useBoard } from "hooks";

function MagazinePage() {
  const navigate = useNavigate();
  const bannerInfo = useBanner({ type: 4 });
  const boardInfo = useBoard({
    groupListOption: {
      page: 1,
      recordSize: 10,
      pageSize: 1,
      type: 1,
    },
  });

  const banner = bannerInfo.getBanner?.data?.data;

  return (
    <div className="wrap">
      <common.Header />

      <Styles.Container>
        <layout.Menu />

        <section className="mainBannerSection">
          {/* //*1 banner */}
          <layout.MainBanner data={banner} />
        </section>

        <section className="magazineSection">
          {boardInfo.groupListQuery.data?.data?.map((item, index) => {
            return (
              <div
                className="magazineContainer"
                key={"magazineContainer" + index}
              >
                <layout.SectionTitle
                  title={
                    <>
                      {item.subtitle}{" "}
                      <span
                        className="categoryName"
                        onClick={() => {
                          navigate(`/magazine/category/${item.id}`);
                        }}
                      >
                        {item.title}
                      </span>
                    </>
                  }
                  url={`/magazine/category/${item.id}`}
                />

                <layout.ContentsSlider
                  slider={"magazine"}
                  data={item?.board_list}
                  urlCallback={(e) => {
                    navigate("/magazine/detail/" + e.id);
                  }}
                  url={`/magazine/category/${item.id}`}
                />
              </div>
            );
          })}
        </section>
      </Styles.Container>

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default MagazinePage;
