import styled from "styled-components";

const Container = styled.main`
  position: relative;

  & section {
    padding: 40px 0;
    border-bottom: 10px solid var(--gray03);
  }

  & .noticeSection {
    & .icon {
      width: 80px;
      height: 80px;
      margin: 0 auto 15px;
      filter: var(--main-filter);
    }

    & .title {
      margin-bottom: 40px;
      font-size: 30px;
      font-weight: 600;
      text-align: center;

      & span {
        color: var(--main);
      }
    }

    & .table {
      margin-bottom: 20px;

      & .tableTitle {
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
      }

      & ul {
        border-top: 1px solid var(--gray02);
        border-bottom: 1px solid var(--gray02);
      }

      & .tableList {
        display: flex;

        &:not(:last-child) {
          border-bottom: 1px solid var(--gray02);
        }

        & .th,
        .td {
          display: flex;
          align-items: center;
          height: 60px;
        }

        & .th {
          width: 230px;
          padding: 0 18px;
          font-weight: 600;
          background-color: var(--gray03);
        }

        & .td {
          padding: 0 42px;
        }
      }
    }

    & .guide {
      font-size: 18px;
      font-weight: 400;
      color: var(--gray01);
      line-height: 1.55;
    }
  }

  & .termsSection {
    & .termsLinkList {
      display: flex;
      justify-content: space-between;
      font-size: 22px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      & .icon {
        width: 16px;
        height: 16px;
        filter: var(--gray01-filter);
      }
    }
  }

  & .check {
    width: fit-content;
    margin: 40px auto;
  }

  & .buttonContainer {
    padding-bottom: 50px;

    & .inner {
      display: flex;
      justify-content: center;
      gap: 10px;

      & .buttonWrap {
        flex: 1;
      }
    }
  }

  @media (max-width: 520px) {
    & section {
      padding: calc(100vw * (40 / 500)) 0;
      border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);
    }

    & .noticeSection {
      & .icon {
        width: calc(100vw * (80 / 500));
        height: calc(100vw * (80 / 500));
        margin: 0 auto calc(100vw * (15 / 500));
      }

      & .title {
        margin-bottom: calc(100vw * (40 / 500));
        font-size: calc(100vw * (30 / 500));
      }

      & .table {
        margin-bottom: calc(100vw * (20 / 500));

        & .tableTitle {
          margin-bottom: calc(100vw * (20 / 500));
          font-size: calc(100vw * (22 / 500));
        }

        & .tableList {
          & .th,
          .td {
            height: calc(100vw * (60 / 500));
          }

          & .th {
            width: calc(100vw * (230 / 500));
            padding: 0 calc(100vw * (18 / 500));
          }

          & .td {
            padding: 0 calc(100vw * (42 / 500));
          }
        }
      }

      & .guide {
        font-size: calc(100vw * (18 / 500));
      }
    }

    & .termsSection {
      & .termsLinkList {
        font-size: calc(100vw * (22 / 500));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 500));
        }

        & .icon {
          width: calc(100vw * (16 / 500));
          height: calc(100vw * (16 / 500));
        }
      }
    }

    & .check {
      margin: calc(100vw * (40 / 500)) auto;
    }

    & .buttonContainer {
      padding-bottom: calc(100vw * (50 / 500));

      & .inner {
        gap: calc(100vw * (10 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
