import { api } from "service";
import { useQuery } from "react-query";

export default function useTransaction({
  transactionList,
  transactionDetailOption,
}) {
  const detailQuery = useQuery(
    ["transacitonDetail", transactionDetailOption],
    () => {
      return api.transaction.getDetail(transactionDetailOption);
    },
    {
      enabled: !!transactionDetailOption,
      refetchOnWindowFocus: false,
    }
  );

  const getTotalPrice = () => {
    let totalPrice = 0;
    let totalProductPrice = 0;

    for (let i = 0; i < transactionList.length; i++) {
      const { detail, optionList, amount } = transactionList[i];

      let productCount = 0;

      let price = 0;
      let optionPrice = 0;

      let productDetail = detail;
      if (!productDetail) return 0;

      price = parseInt(productDetail?.price);

      let isSelectOptionCount = 0;
      optionList.map((group) => {
        let isOption = 0;

        group?.list.map((option) => {
          if (option.checked) {
            isOption++;
            optionPrice += parseInt(option.price);

            if (detail?.type != "2") {
              productCount++;
            }
          }

          option?.option_relate_list?.map((value) => {
            if (value.checked) {
              optionPrice += parseInt(value.price);
            }
          });
        });

        if (isOption) isSelectOptionCount++;
      });

      // if(isSelectOptionCount != optionList?.length){
      //   return 0;
      // }

      if (detail?.type != "2" && productCount > 0) {
        price = price * productCount;
      }
      totalPrice += (price + optionPrice) * amount;
    }

    return totalPrice;
  };

  const isValidate = () => {
    for (let i = 0; i < transactionList.length; i++) {
      if (
        transactionList[i].detail?.type != "4" &&
        transactionList[i].optionList.length == 0
      ) {
        return false;
      }

      const { detail, optionList, amount } = transactionList[i];
      let isSelectOptionCount = 0;

      optionList.map((group) => {
        let isOption = 0;
        group?.list.map((option) => {
          if (option.checked) {
            isOption++;
          }
        });
        if (isOption) {
          isSelectOptionCount++;
        }
      });

      if (isSelectOptionCount == 0) {
        return false;
      }
    }

    return true;
  };

  const getGenerateTransactionList = () => {
    let resultList = [];

    transactionList.map((value) => {
      const { detail, optionList, amount, date } = value;
      let resultOptionList = [];
      let resultOptionRelateList = [];

      optionList.map((group) => {
        group?.list?.map((option) => {
          if (option.checked) {
            resultOptionList.push({
              amount: 1,
              id: option.id,
              relate_info_id: 0,
              title: option.title,
              product_title: detail?.title,
              price: parseInt(option.price),
            });

            option?.option_relate_list?.map((value) => {
              if (value.checked) {
                resultOptionRelateList.push({
                  amount: 1,
                  id: value.id,
                  relate_info_id: option.id,
                  title: value.title,
                  product_title: value.product_title,
                  price: parseInt(value.price),
                });
              }
            });
          }
        });
      });

      resultList.push({
        type: detail?.type,
        date: date,
        amount: amount > 0 ? amount : 1,
        option_list: resultOptionList,
        option_relate_list: resultOptionRelateList,
        product: {
          amount: detail?.type != "2" ? resultOptionList.length : 1,
          id: detail?.id,
          price: parseInt(detail?.price),
        },
      });
    });

    return resultList;
  };

  return {
    detailQuery,
    transactionList,

    getTotalPrice,
    isValidate,

    getGenerateTransactionList,
  };
}
