import Styles from "./styles";
import utils from "utils";
import { api } from "service";
import { common, ui } from "components";
import { useEffect, useRef, useState } from "react";
import { useAddress, useAuth, useCodeCheck, useModals, useTimer } from "hooks";
import DaumPostcodeEmbed from "react-daum-postcode";

// img
import IconWarning from "resources/image/icon/icon_warning.svg";
import { iOS } from "utils/etc";

function SignupStepBPage() {
  const modalOption = useModals();
  const authInfo = useAuth({});
  const codeCheckInfo = useCodeCheck();
  const address = useAddress();

  const [inputs, setInputs] = useState({
    nickname: "",
    name: "",
    gender: "",
    tel: "",
    confirmCode: "",
    addressNumber: "",
    address: "",
    detailAddress: "",
    recommendCode: "",
    discountCode: "",
  });
  const [myDate, setMyDate] = useState("");
  const [checkCode, setCheckCode] = useState({
    recommendCode: "",
    extraCode: "",
  });
  const [confirmCodeSend, setConfirmCodeSend] = useState(false);
  const [confirmComplete, setConfirmComplete] = useState(false);
  const [nameCheck, setNameCheck] = useState(false);
  const [check, setCheck] = useState([]);
  const [isBlock, setIsBlock] = useState(0);
  const timer = useTimer();
  const isLoad = useRef(false);

  const sendIdentify = async () => {
    if (inputs.tel.replace(/-/gi, "").replace(/[^0-9]/g, "").length != 11)
      return;
    if (isBlock >= 5) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "시도 횟수가 많아 처음부터 진행 부탁드리겠습니다.";
        return { ...e };
      });
      return;
    }
    let result = await authInfo.sendIdentifyCode(inputs.tel, "phone");
    modalOption.setToastModal((e) => {
      e.show = true;
      e.text = "인증번호가 발송되었습니다.";
      return { ...e };
    });

    timer.clickTimer();
    setConfirmCodeSend(true);
    setIsBlock((e) => e + 1);
  };

  const identifyCode = async () => {
    if (inputs.confirmCode == "") {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "휴대폰 번호를 입력해 주세요.";
        return { ...e };
      });
      return;
    }
    if (timer.min == "00" && timer.sec == "00") {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "인증 시간이 만료되었습니다.";
        return { ...e };
      });
      return;
    }
    let resultData = {
      identify_number: inputs.tel,
      code: inputs.confirmCode,
      type: 1,
    };
    let result = await api.sms.checkSms(JSON.stringify(resultData));
    if (result.success == true) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "인증이 완료되었습니다.";
        return { ...e };
      });
      setConfirmComplete(true);
    } else {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "인증번호가 일치하지 않습니다.";
        return { ...e };
      });
      setConfirmComplete(false);
    }
  };

  // 단일 체크 함수
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheck(check.concat(id));
    } else {
      setCheck(check.filter((el) => el !== id));
    }
  };

  const onNameCheck = async () => {
    const result = await authInfo.findNickName(inputs.nickname);
    result?.success ? setNameCheck(true) : setNameCheck(false);
    if (result?.success) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "사용 가능한 닉네임 입니다.";
        return { ...e };
      });
    } else {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "사용 불가능한 닉네임 입니다.";
        return { ...e };
      });
    }
  };

  const doRecommendCheck = async () => {
    const result = await codeCheckInfo.checkRecommend(inputs.discountCode);
    if (result?.success) {
      setCheckCode((e) => {
        return { ...e, extraCode: inputs.discountCode };
      });
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "추천인코드가 확인 되었습니다.";
        return { ...e };
      });
    } else {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "추천인코드가 존재하지 않습니다.";
        return { ...e };
      });
    }
  };

  const doDiscountCheck = async () => {
    const result = await codeCheckInfo.checkPromotion(inputs.discountCode);
    if (result?.success) {
      setCheckCode((e) => {
        return { ...e, extraCode: inputs.discountCode };
      });
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "쿠폰코드가 확인 되었습니다.";
        return { ...e };
      });
    } else {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = result?.description == "사용이 만료된 쿠폰번호입니다." ? "사용이 만료된 쿠폰번호입니다." : "등록되지 않은 쿠폰코드 입니다.";
        return { ...e };
      });
    }
  };

  const validate = () => {
    if (!inputs.nickname) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "닉네임을 입력해 주세요.";
        return { ...e };
      });
      return false;
    }
    if (!nameCheck) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "닉네임 중복확인을 해주세요.";
        return { ...e };
      });
      return false;
    }
    if (inputs.nickname < 2 || inputs.nickname.length > 10) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "닉네임을 입력해 주세요.";
        return { ...e };
      });
      return false;
    }
    if (utils.etc.iOS() == false) {
      if (!inputs.name) {
        modalOption.setToastModal((e) => {
          e.show = true;
          e.text = "이름을 입력해 주세요.";
          return { ...e };
        });
        return false;
      }
      if (!inputs.gender) {
        modalOption.setToastModal((e) => {
          e.show = true;
          e.text = "성별을 선택해 주세요.";
          return { ...e };
        });
        return false;
      }
      if (!myDate || !myDate.birthday) {
        modalOption.setToastModal((e) => {
          e.show = true;
          e.text = "생년월일을 선택해 주세요.";
          return { ...e };
        });
        return false;
      }
    }

    if (
      !inputs.tel ||
      inputs.tel.replace(/-/gi, "").replace(/[^0-9]/g, "").length != 11
    ) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "휴대폰 번호를 입력해 주세요.";
        return { ...e };
      });
      return false;
    }
    if (!confirmCodeSend) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "휴대폰 인증을 진행해 주세요.";
        return { ...e };
      });
      return false;
    }
    if (!confirmComplete) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "휴대폰 인증을 진행해 주세요.";
        return { ...e };
      });
      return false;
    }
    if (inputs.recommendCode !== "" && checkCode.recommendCode === "") {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "추천인코드를 확인해 주세요.";
        return { ...e };
      });
      return false;
    }
    if (inputs.discountCode !== "" && checkCode.extraCode === "") {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "쿠폰코드를 확인해 주세요.";
        return { ...e };
      });
      return false;
    }
    return true;
  };

  const doRegister = async () => {
    let result = {
      nickname: inputs.nickname,
      name: inputs.name,
      address: address.address,
      postcode: address.zipcode,
      address_detail: inputs.detailAddress,
      birthday: utils.date.getDateType(myDate?.birthday, "-"),
      gender: inputs.gender,
      phone: inputs.tel,

      policy_yn: sessionStorage.getItem("signUp")?.policy_yn == "Y" ? "Y" : "N",
      marketing_yn:
        sessionStorage.getItem("signUp")?.marketing_yn == "Y" ? "Y" : "N",
      personal_policy_yn:
        sessionStorage.getItem("signUp")?.personal_policy_yn == "Y" ? "Y" : "N",

      mail_yn: sessionStorage.getItem("signUp")?.mail_yn == "Y" ? "Y" : "N",
      message_yn:
        sessionStorage.getItem("signUp")?.message_yn == "Y" ? "Y" : "N",
      push_yn: sessionStorage.getItem("signUp")?.push_yn == "Y" ? "Y" : "N",

      spouse_yn: check.filter((v) => v == "family01")[0] ? "Y" : "N",
      son_yn: check.filter((v) => v == "family02")[0] ? "Y" : "N",
      daughter_yn: check.filter((v) => v == "family03")[0] ? "Y" : "N",
      parent_yn: check.filter((v) => v == "family04")[0] ? "Y" : "N",
      grandchildren_yn: check.filter((v) => v == "family05")[0] ? "Y" : "N",
      pet_yn: check.filter((v) => v == "family06")[0] ? "Y" : "N",

      recommend_code: checkCode.recommendCode,
      extra_code: checkCode.extraCode,
    };
    if (validate()) {
      if (isLoad.current) return;
      isLoad.current = true;
      await authInfo.socialSignUp(result);
    }
  };

  useEffect(() => {
    setNameCheck(false);
  }, [inputs.nickname]);

  useEffect(() => {
    setConfirmCodeSend(false);
  }, [inputs.phone]);

  // data
  const inputList = [
    {
      essential: true,
      name: "nickname",
      value: inputs.nickname,
      labelText: "닉네임",
      placeholder: "최대 10자, 한글, 영문, 숫자만 입력",
    },
    {
      essential: true,
      value: inputs.name,
      name: "name",
      labelText: "이름",
      placeholder: "정확한 예약 확인을 위해 실명을 적어주세요.",
    },
    {
      essential: true,
      value: inputs.gender,
      name: "gender",
      labelText: "성별",
    },
    {
      essential: true,
      value: inputs.tel,
      name: "tel",
      type: "tel",
      labelText: "휴대폰",
      placeholder: "숫자만 입력",
    },
    {
      value: inputs.confirmCode,
      name: "confirmCode",
      type: "number",
      placeholder: "인증번호 입력",
      readOnly: confirmComplete,
    },
    {
      name: "addressNumber",
      value: address.zipcode,
      labelText: "거주지",
      placeholder: "우편번호",
      readOnly: true,
    },
    {
      name: "address",
      value: address.address,
      placeholder: "본주소",
      readOnly: true,
    },
    {
      name: "detailAddress",
      value: inputs.detailAddress,
      placeholder: "상세주소",
    },
    {
      name: "recommendCode",
      value: inputs.recommendCode,
      labelText: "추천인코드",
      placeholder: "추천코드 입력",
    },
    {
      name: "discountCode",
      value: inputs.discountCode,
      labelText: "쿠폰코드",
      placeholder: "쿠폰 코드 입력",
    },
  ];
  const genderList = [
    { id: "W", text: "여성" },
    { id: "M", text: "남성" },
    { id: "E", text: "선택안함" },
  ];
  const familyList = [
    {
      id: "family01",
      labelText: "배우자",
    },
    {
      id: "family02",
      labelText: "아들",
    },
    {
      id: "family03",
      labelText: "딸",
    },
    {
      id: "family04",
      labelText: "부모님",
    },
    {
      id: "family05",
      labelText: "손주",
    },
    {
      id: "family06",
      labelText: "반려동물",
    },
  ];

  return (
    <div className="wrap">
      <common.SubHeader pageName={"회원가입"} />

      <Styles.Container>
        <div className="inner">
          <h2>회원 기본 정보를 입력해 주세요.</h2>

          <ul className="listWrap">
            <li className="list">
              <ui.input.BasicInput
                data={inputList[0]}
                setValue={(e) => {
                  setInputs((input) => {
                    return {
                      ...input,
                      [inputList[0].name]: e.target.value
                        .replace(/[^0-9a-zA-Z가-힣ㆍᆢㄱ-ㅎㅏ-ㅣ]*$/gi, "")
                        .slice(0, 10),
                    };
                  });
                }}
              >
                <div className="confirmButton">
                  <ui.button.BasicButton
                    size={"smallText"}
                    buttonText={"중복확인"}
                    buttonType={"black"}
                    onClick={onNameCheck}
                  />
                </div>
              </ui.input.BasicInput>
            </li>

            <li className="list">
              <div className="inputContainer">
                <ui.input.BasicInput
                  data={inputList[1]}
                  setInputs={setInputs}
                  noRequied={iOS()}
                />
              </div>
            </li>

            <li className="list gender">
              <p className="label">
                <span>{utils.etc.iOS() ? "[선택]" : "[필수]"}</span> 성별
              </p>

              <ul>
                {genderList.map((item, index) => {
                  return (
                    <li
                      className="radioInputContainer"
                      key={"radioInputContainer" + index}
                    >
                      <ui.input.BasicRadio
                        id={item.id}
                        checked={inputs.gender}
                        name={"gender"}
                        labelText={item.text}
                        setInputs={setInputs}
                      />
                    </li>
                  );
                })}
              </ul>
            </li>

            <li className="list">
              <ui.input.DateInput
                labelText={"생년월일"}
                name={"birthday"}
                startDate={myDate.birthday}
                setStartDate={setMyDate}
                readOnly={true}
              />
            </li>

            <li className="list phone">
              <div className="inputContainer">
                <ui.input.BasicInput
                  data={{
                    ...inputList[3],
                    value:
                      inputs.tel.substr(0, 3) +
                      (inputs.tel.length > 3
                        ? "-" +
                          inputs.tel.substr(3, 4) +
                          (inputs.tel.length > 7
                            ? "-" + inputs.tel.substr(7, 4)
                            : "")
                        : ""),
                  }}
                  setValue={(e) => {
                    setConfirmCodeSend(false);
                    setConfirmComplete(false);
                    setInputs((input) => {
                      return {
                        ...input,
                        [inputList[3].name]: e.target.value
                          .replace(/-/gi, "")
                          .replace(/[^0-9]/gi, "")
                          .slice(0, 11),
                      };
                    });
                  }}
                >
                  <div className="confirmButton">
                    <ui.button.BasicButton
                      size={"smallText"}
                      buttonText={confirmCodeSend ? "재전송" : "인증번호 발송"}
                      buttonType={"black"}
                      // disabled={isBlock <= 3}
                      onClick={sendIdentify}
                    />
                  </div>
                </ui.input.BasicInput>
              </div>

              {confirmCodeSend && (
                <div className="inputContainer">
                  <div className="inputWrap">
                    <ui.input.BasicInput
                      data={inputList[4]}
                      setInputs={setInputs}
                    >
                      <div className="confirmButton">
                        <ui.button.BasicButton
                          size={"smallText"}
                          buttonText={confirmComplete ? "인증완료" : "인증하기"}
                          buttonType={"black"}
                          disabled={confirmComplete}
                          onClick={identifyCode}
                        />
                      </div>
                      {!confirmComplete && (
                        <span className="time">
                          {timer.min}:{timer.sec}
                        </span>
                      )}
                    </ui.input.BasicInput>
                  </div>

                  <p className="guideText">
                    <span className="icon">
                      <img src={IconWarning} alt="IconWarning" />
                    </span>
                    인증번호가 오지 않을경우 정보를 다시 확인 후 <br />
                    인증번호 재발송 해주세요.
                  </p>
                </div>
              )}
            </li>

            <li className="list address">
              <div className="inputContainer">
                <ui.input.BasicInput data={inputList[5]} setInputs={setInputs}>
                  <div className="confirmButton">
                    <ui.button.BasicButton
                      size={"smallText"}
                      buttonText={"검색"}
                      buttonType={"black"}
                      onClick={address.handleClick}
                    />
                  </div>
                </ui.input.BasicInput>
              </div>
              <div className="inputContainer">
                <ui.input.BasicInput
                  data={inputList[6]}
                  setInputs={setInputs}
                />
              </div>
              <div className="inputContainer">
                <ui.input.BasicInput
                  data={inputList[7]}
                  setInputs={setInputs}
                />
              </div>
            </li>

            <li className="list">
              <ui.input.BasicInput data={inputList[8]} setInputs={setInputs}>
                <div className="confirmButton">
                  <ui.button.BasicButton
                    size={"smallText"}
                    buttonText={"확인"}
                    buttonType={"black"}
                    onClick={doRecommendCheck}
                  />
                </div>
              </ui.input.BasicInput>
            </li>

            <li className="list">
              <ui.input.BasicInput data={inputList[9]} setInputs={setInputs}>
                <div className="confirmButton">
                  <ui.button.BasicButton
                    size={"smallText"}
                    buttonText={"확인"}
                    buttonType={"black"}
                    onClick={doDiscountCheck}
                  />
                </div>
              </ui.input.BasicInput>
            </li>

            <li className="list family">
              <p className="label">가족 구성원</p>
              <p className="guideText">중복 선택이 가능합니다.</p>

              <ul>
                {familyList?.map((item, index) => {
                  return (
                    <li className="familyList" key={"familyList" + index}>
                      <ui.input.BasicCheckbox
                        id={item.id}
                        labelText={item.labelText}
                        checked={check.includes(item.id) ? true : false}
                        onChange={(e) =>
                          handleSingleCheck(e.target.checked, e.target.id)
                        }
                      />
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>

          <div className="nextButton">
            <ui.button.BasicButton
              buttonText={"가입완료"}
              onClick={doRegister}
            />
          </div>
        </div>
        <address.RenderAddress></address.RenderAddress>
      </Styles.Container>
    </div>
  );
}

export default SignupStepBPage;
