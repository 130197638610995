import Styles from "./styles";
import { common, ui, layout } from "components";
import { Link, useNavigate } from "react-router-dom";
import { api } from "service";
import { useAuth, useCodeCheck, useModals } from "hooks";
import { useState } from "react";

// img
import ImgUserProfileDefault from "resources/image/img_default_profile.svg";
import IconProgram from "resources/image/icon/icon_program.svg";
import IconLike from "resources/image/icon/icon_like_off.svg";
import IconCoupon from "resources/image/icon/icon_coupon_black.svg";
import IconSmile from "resources/image/icon/icon_smile.svg";
import IconCheck from "resources/image/icon/icon_check_gradient.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right.svg";
import IconArrowRightMini from "resources/image/icon/icon_arrow_right_mini.svg";
import IconLocation from "resources/image/icon/icon_location.svg";
import IconBox from "resources/image/icon/icon_box.svg";
import IconCopy from "resources/image/icon/icon_copy.svg";
import useOrder from "hooks/useOrder";
import utils from "utils";

function MyPage() {
  const modalOption = useModals();
  const navigate = useNavigate();
  const authInfo = useAuth({});
  const codeCheckInfo = useCodeCheck();

  const data = authInfo.userData;
  const orderInfo = useOrder({
    listOption: {
      page: 1,
      recordSize: 3,
      pageSize: 1,
      state: "20,21,22,23",
    },
  });
  const list = orderInfo?.listQuery?.data?.list || [];

  const myMenuList = [
    {
      icon: IconProgram,
      text: "프로그램",
      url: "program",
    },
    {
      icon: IconLike,
      text: "나의 찜",
      url: "like",
    },
    {
      icon: IconCoupon,
      text: "나의 혜택",
      url: "benefit",
    },
    {
      icon: IconSmile,
      text: "나의 정보",
      url: "info",
    },
  ];

  const [inputs, setInputs] = useState({
    coupon: "",
  });

  const couponData = {
    value: inputs.coupon,
    name: "coupon",
    labelText: "쿠폰번호를 입력해 주세요!",
    placeholder: "쿠폰번호 입력",
  };

  const doDiscountCheck = async () => {
    const result = await codeCheckInfo.doPromotion(couponData.value);
    if (result?.success) {
      authInfo.userQuery.refetch();
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "쿠폰코드가 확인 되었습니다.";
        return { ...e };
      });
    } else {
      

      modalOption.setToastModal((e) => {
        e.show = true;
        e.text =
         result.description == "사용이 만료된 쿠폰번호입니다." ? "사용이 만료된 쿠폰번호입니다." 
         : result.description == "이미 사용한 프로모션 코드입니다."
            ? "이미 사용된 쿠폰번호 입니다."
            : "등록되지 않은 쿠폰번호 입니다.";
        return { ...e };
      });
    }
  };

  return (
    <div className="wrap">
      <common.SubHeader noPrevButton pageName={"마이페이지"} />

      <Styles.Container>
        <section className="mySection">
          <div className="inner">
            <div className="userInfo">
              <div className="profileImg">
                <img
                  src={data.image ? data.image : ImgUserProfileDefault}
                  alt="userImg"
                />
              </div>

              <p className="nickname">{data.nickname}</p>
            </div>

            <div className="couponWrap">
              <ui.input.BasicInput data={couponData} setInputs={setInputs}>
                <div className="confirmButton insideButton">
                  <ui.button.BasicButton
                    size={"smallText"}
                    buttonText={"확인"}
                    buttonType={"black"}
                    onClick={doDiscountCheck}
                  />
                </div>
              </ui.input.BasicInput>
            </div>

            <div className="pointWrap">
              <p>보유 포인트</p>

              <div className="point">
                {utils.etc.numberWithCommas(data.total_point)} P
              </div>
            </div>

            <ul className="myMenu">
              {myMenuList.map((item, index) => {
                return (
                  <li className="myMenuList" key={"myMenuList" + index}>
                    <Link to={`/mypage/${item.url}`}>
                      <div className="icon">
                        <img src={item.icon} alt="myMenuIcon" />
                      </div>
                      {item.text}
                    </Link>
                  </li>
                );
              })}
            </ul>

            {/* 관심사 등록 전에만 보이도록 해주세요 */}
            {data && data?.interest_yn == "N" && (
              <Link to={"/interest"} className="interest">
                <div className="checkIcon">
                  <img src={IconCheck} alt="IconCheck" />
                </div>

                <div className="textWrap">
                  <p>관심사 등록하기</p>
                  <p>관심사를 등록하고 1,000 포인트를 받아보세요!</p>
                </div>

                <div className="arrowIcon">
                  <img src={IconArrowRight} alt="IconArrowRight" />
                </div>
              </Link>
            )}
          </div>
        </section>

        <section className="programSection">
          <div className="inner">
            <div className="titleContainer">
              <p className="contentsTitle">나의 프로그램 일정</p>

              <Link to={"/mypage/program"}>
                더보기{" "}
                <div className="icon">
                  <img src={IconArrowRightMini} alt="IconArrowRightMini" />
                </div>
              </Link>
            </div>

            {list?.length > 0 ? (
              <>
                <ul className="program">
                  {list?.map((item, index) => {
                    return (
                      <li className="programList" key={"program" + index}>
                        <Link to={"/mypage/payment-history?id=" + item.id}>
                          <div className="top">
                            <div className="tag">
                              <layout.Tag type={item.type} />
                            </div>

                            <div className="location">
                              <div className="icon">
                                <img src={IconLocation} alt="IconLocation" />
                              </div>
                              {item.location}
                            </div>
                          </div>

                          <div className="date">
                            {utils.date.getDateType(item.started_at, "-")}{" "}
                            <span className="people">{item.amount}명</span>
                          </div>

                          <ul className="detailProgram">
                            {item.option_list?.map((current, idx) => {
                              return (
                                <li
                                  className="detailList"
                                  key={"detailList" + idx}
                                >
                                  <div className="detailTop">
                                    <p className="detailTitle">
                                      {current.product_title}
                                    </p>

                                    <p className="time">{current.title}</p>
                                  </div>

                                  <div className="detailBottom">
                                    <div className="icon">
                                      <img src={IconBox} alt="IconBox" />
                                    </div>
                                    강의실
                                    <span className="room">
                                      {current.product_room_title ||
                                        current.room_title}
                                    </span>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </Link>
                      </li>
                    );
                  })}
                </ul>

                <div className="moreButton">
                  <ui.button.BasicButton
                    buttonText={"전체보기"}
                    onClick={() => {
                      navigate("/mypage/program");
                    }}
                  />
                </div>
              </>
            ) : (
              <p className="emptyText">프로그램 내역이 없습니다.</p>
            )}
          </div>
        </section>

        <section className="utilSection">
          <div className="inner">
            <div className="codeContainer">
              <p className="contentsTitle">내 추천인 코드</p>

              <div className="codeWrap">
                <button
                  type="button"
                  onClick={() => {
                    utils.url.getDetailCopy(data.my_recommend_code);
                    modalOption.setToastModal((e) => {
                      e.show = true;
                      e.text = "추천인 코드가 복사되었습니다.";
                      return { ...e };
                    });
                  }}
                >
                  <span className="icon">
                    <img src={IconCopy} alt="IconCopy" />
                  </span>

                  <span className="code">{data.my_recommend_code}</span>
                </button>
              </div>
            </div>

            <ul className="util">
              <li className="utilList">
                <a href="tel:02-2038-2636">
                  고객센터 <span>02-2038-2636</span>
                </a>
              </li>

              <li className="utilList">
                <Link to={"/contact/notice"}>공지사항</Link>
              </li>

              <li className="utilList">
                <button type="button" onClick={authInfo.logoutModal}>
                  로그아웃
                </button>
              </li>
            </ul>
          </div>
        </section>
      </Styles.Container>

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}
export default MyPage;
